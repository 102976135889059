<template>
    <mercur-card v-if="isAllowedTo('SupplierCentral/getSupplierInvoicesBySupplierId')" class="full-height-layout fill mx-4 mb-3">
        <grid-header :quickSearch.sync="filters.search">
            Invoices
            <div slot="filters" class="daterangepicker-wrapper">
                <date-range-picker
                    @update="filterDateRangeUpdate"
                    :timePicker="false"
                    :dateRange="filters.dateRange"
                    :ranges="false"
                    opens="right"
                    ref="dateRangePicker"
                    :show-week-numbers="true"
                    class="date-picker"
                >
                    <template slot="preview-range" slot-scope="preview">
                        <strong>{{preview.startText}}</strong>
                        &nbsp;to&nbsp;
                        <strong>{{preview.endText}}</strong>
                    </template>
                    <template slot="input">
                        <mercur-input
                            v-model="parsedDateRange"
                            @keypress.enter.prevent="handleManualDateInput">
                            Date range
                        </mercur-input>
                    </template>
                </date-range-picker>
            </div>
        </grid-header>
        <data-table
            class="fill full-height-layout border"
            :options="options"
            ref="grid"
            :url="url"
            :quickSearch="filters.search"
        ></data-table>
    </mercur-card>
    <div v-else>
        <p class="permission-message">Not allowed to see this view</p>
    </div>
</template>
<script>
import GridHeader from '../../../components/GridHeader'
import DataTable from '../../../components/DataTable'
import CONFIG from '@root/config'
import moment from 'moment'
import DateRangePicker from '../../../components/utils/DateRangePicker'

export default {
    name: 'InvoicesOverview',
    components: { DataTable, GridHeader, DateRangePicker },
    data () {
        return {
            options: {
                masterDetail: true,
                // specify params for default detail cell renderer
                detailCellRendererParams: {
                    // provide detail grid options
                    detailGridOptions: {
                        columnDefs: [
                            {
                                colId: 'orderLines',
                                headerName: 'Order',
                                valueGetter: (params) => `${params.data.orderNumber} - ${params.data.orderLineNumber}`,
                            },
                            {
                                field: 'dispatchDate',
                            },
                        ],
                    },

                    getDetailRowData: params => {
                        params.successCallback(params.data.orderLines)
                        setTimeout(() => {
                            params.node.detailGridInfo.api.sizeColumnsToFit()
                        }, 1)
                    },
                },
                columns: {
                    'Invoice number': {
                        field: 'supplierInvoiceNumber',
                        sortable: false,
                        cellRenderer: 'agGroupCellRenderer',
                    },
                    'Orders': {
                        field: 'orderLines',
                        sortable: false,
                        valueGetter ({ data }) {
                            return data.orderLines.map(orderLine => `${orderLine.orderNumber} - ${orderLine.orderLineNumber}`)
                        },
                        valueFormatter ({ data }) {
                            return data.orderLines.length
                        },
                    },
                    'Invoice date': {
                        field: 'supplierInvoiceDate',
                        sortable: true,
                    },
                    'Status': {
                        field: 'supplierInvoiceStatus',
                        sortable: false,
                    },
                    'orderNumber': {
                        hide: true,
                        colId: 'orderNumber',
                    },
                    'orderLineNumber': {
                        hide: true,
                        colId: 'orderLineNumber',
                    },
                    'dispatchDateFrom': {
                        hide: true,
                        colId: 'dispatchDateFrom',
                    },
                    'dispatchDateTo': {
                        hide: true,
                        colId: 'dispatchDateTo',
                    },
                },
                quickSearchColumns: ['orderNumber', 'orderLineNumber'],
            },
            url: CONFIG.API.ROUTES.ORDERS.INVOICES.OVERVIEW.replace('{supplierId}', this.supplierId),
            filters: {
                search: null,
                dateRange: {
                    startDate: moment(),
                    endDate: moment(),
                },
            },
            dateRangeFrom: null,
            dateRangeTo: null,
        }
    },
    computed: {
        parsedDateRange: {
            get () {
                if (this.dateRangeFrom === null || this.dateRangeTo === null) {
                    return ''
                }
                if (!this.dateRangeFrom.isValid() || !this.dateRangeTo.isValid()) {
                    return ''
                }

                return this.formatDate(this.dateRangeFrom, true) + ' - ' + this.formatDate(this.dateRangeTo, true)
            },
            set (value) {
                if (typeof value !== 'string') {
                    return
                }

                let [start, end] = value.split(' - ')

                start = moment(start)
                end = moment(end)

                if (start.isValid()) {
                    this.dateRangeFrom = start
                }
                if (end.isValid()) {
                    this.dateRangeTo = end
                }
            },
        },
    },
    methods: {
        filterDateRangeUpdate (value) {
            if (!value || value.dateRangeFrom === null || value.dateRangeTo === null) {
                this.dateRangeFrom = null
                this.dateRangeTo = null

                return
            }
            this.dateRangeFrom = moment(value.startDate)
            this.dateRangeTo = moment(value.endDate)
        },
        handleManualDateInput ($event) {
            this.parsedDateRange = $event.target.value
            $event.target.blur()
            this.$refs.dateRangePicker.open = false
            this.applyDateFilters()
        },
        applyDateFilters () {
            if (!this.dateRangeTo.isValid() || !this.dateRangeFrom.isValid()) {
                const dispatchDateToFilter = this.$refs.grid.gridApi.getFilterInstance('dispatchDateTo')
                const dispatchDateFromFilter = this.$refs.grid.gridApi.getFilterInstance('dispatchDateFrom')
                dispatchDateToFilter.setModel(null)
                dispatchDateFromFilter.setModel(null)
                this.$refs.grid.gridApi.onFilterChanged()
                return
            }

            const filterModel = this.$refs.grid.gridApi.getFilterModel()

            this.$refs.grid.applyFilter({
                ...filterModel,
                dispatchDateTo: {
                    filter: this.formatDate(this.dateRangeTo, true),
                    filterType: 'text',
                    type: 'lessThan',
                },
                dispatchDateFrom: {
                    filter: this.formatDate(this.dateRangeFrom, true),
                    filterType: 'text',
                    type: 'greaterThan',
                },
            })
        },
        formatDate (date, allDay) {
            return date.format(allDay ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm')
        },
    },
    created () {
        this.$watch(function () {
            return [this.dateRangeFrom, this.dateRangeTo]
        }, function (newValues, oldValues) {
            if (JSON.stringify(newValues) === JSON.stringify(oldValues)) {
                return
            }
            if (!this.$refs || !this.$refs.grid) {
                return
            }
            this.applyDateFilters()
        }, {
            immediate: true,
        })
    },
}
</script>

<style lang="scss" scoped>
    .date-picker {
        min-width: 250px;
    }
</style>
